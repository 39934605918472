import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { PlatformService } from './platform.service';
import { WrSocketService } from './wr-sockets.service';
import { environment } from 'src/environments/environment';
import { GlobalEventsService } from './global-events.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private _localesForHeader = ['en', 'fi', 'de', 'en-ca', 'en-nz', 'fr-ca', 'de-at'];
  private _localesForLangTag = ['en', 'fi', 'de', 'fr'];

  constructor(
    private _meta: Meta,
    private _title: Title,
    private _globalEvent: GlobalEventsService,
    private _platform: PlatformService,
    private _location: Location,
    private _wrSocket: WrSocketService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }


  /**
   * Set in head Title & description
   * @param tags
   */
  public setMetaTags(tags: any) {
    this._title.setTitle(tags.MetaTitle || 'Nightrush');
    this._meta.updateTag({name: 'description', content: tags.MetaDesc || ''});

    if ('NoIndex' in tags && tags.NoIndex) {
      this.addMetaRobotNoIndex();
    }
  }

  /**
   * Add new alternate/canonical links and remove old links
   */
  public updateGlobalHeadLink() {
    this._addHeadLinkAlternate();
    this._addHeadLinkCanonicalAndXDefault('canonical');
    this._addHeadLinkCanonicalAndXDefault('x-default');
    this._addLangTagForHTML();
    this._globalEvent.routerNavigationEnd$.subscribe(() => {
        this._wrSocket.sendEventPageOpen();
        this._removeOldLinks(['alternate', 'canonical']);
        this._addHeadLinkAlternate();
        this._addHeadLinkCanonicalAndXDefault('canonical');
        this._addHeadLinkCanonicalAndXDefault('x-default');
        this._addLangTagForHTML();
      });
  }

  /**
   * Add link alternate <link rel="alternate" href="https://new.nightrush.com/en" hreflang="en">
   */
  private _addHeadLinkAlternate() {
    this._localesForHeader.forEach(e => {
      const link: HTMLLinkElement = this._document.createElement('link');
      let url: any = this._location.path().split('/');
      url[1] = e;
      url = url.join('/');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('href', environment.seo_url + url);
      link.setAttribute('hreflang', e);
      this._document.head.appendChild(link);
    });
  }

  /**
   * Add canonical link <link rel="canonical" href="/en">
   */
  private _addHeadLinkCanonicalAndXDefault(attr: string) {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', attr);
    link.setAttribute('href', environment.seo_url + this._location.path().split('?')[0]);
    this._document.head.appendChild(link);
  }

  /**
   * Remove old alternate\canonical links from DOM
   * @param typeOfLinks
   */
  private _removeOldLinks(typeOfLinks: any[]) {
    if (this._platform.isBrowser) {
     typeOfLinks.forEach(e => {
       const oldLinks: NodeListOf<HTMLLinkElement> = this._document.querySelectorAll(`link[rel="${e}"]`);
       if (oldLinks.length) {
         oldLinks.forEach(j => {
           j.remove();
         });
       }
     });
    }
  }

 /**
  * Add in head <meta name="robots" content="noindex" />
  */
  public addMetaRobotNoIndex() {
    this._meta.addTags([
      { name: 'robot', content: 'noindex' }
    ]);
  }

  /**
   * Edit html lang attribute
   */
  private _addLangTagForHTML() {
    const defaultLocale = 'en';
    const matches = this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) ? this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) : this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)/);
    const locale = (matches && this._localesForLangTag.includes(matches[1])) ? matches[1] : defaultLocale;
    this._document.documentElement.lang = locale;
  }
}
